import React, { useEffect, useState } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useNavigate, useLocation } from 'react-router-dom';
import './settings.css';
import { Form, Button } from 'react-bootstrap';
import lockuser from '../../assets/images/lock_icon.png';
import TabNavItem from "../settings-tabs/TabNavItem";
import TabContent from "../settings-tabs/TabContent";
import Button1 from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import female from '../../assets/images/avatarf.png';
import male from '../../assets/images/avatarm.png';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import $ from 'jquery';
import icon from "../../assets/images/lock_icon.png";
import lock from "../../assets/images/lock.svg";
import unlock from "../../assets/images/unlock.svg";
import OtpInput from "react-otp-input";
import NoData from '../../assets/images/no_data.gif';
import Admin from '../../assets/images/interested.png';
import { Card } from 'react-bootstrap';
import CardHeader from "react-bootstrap/esm/CardHeader";
import admin_image from '../../assets/images/Group_new_icon.png';
import { Input } from '@material-ui/core';
import send_btn from '../../assets/images/Send.png';
import arrow from '../../assets/images/back-arrow.svg';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import img from '../../assets/images/logo.svg';
import WidgetsIcon from '@mui/icons-material/Widgets';

const Settings = () => {
  const [activeTab, setActiveTab] = useState("account");
  const [showlock, setShowlock] = useState(false);
  const [showunlock, setShowunlock] = useState(false);
  const [value, setValue] = useState({ 'items': [], 'path': '' })
  const location = useLocation();
  const [country, setcountry] = useState([]);
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  const locked_status = JSON.parse(localStorage.getItem('locked_status'));
  const newQuestion = JSON.parse(localStorage.getItem('newq'));
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const defaultAuth = process.env.REACT_APP_USER_TOKEN;
  const flagUrl = process.env.REACT_APP_FLAG_URL;
  const footerUrl = process.env.REACT_APP_FOOTER_URL;
  const [Terms, setTerms] = useState('')
  const [Privacy, setPrivacy] = useState('')
  const [Guide, setGuide] = useState('')
  const [Cookie, setCookie] = useState('')
  const [lock_status, setlockstatus] = useState(locked_status)
  const [show, setShow] = useState(false);
  const [showblock, setShowblock] = useState(false);
  const [showdelete, setShowdelete] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleCloseblock = () => setShowblock(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShow = () => setShow(true);
  const handleshowdelete = () => setShowdelete(true);
  const [blockid, setblockid] = useState('')
  const [count, setCount] = useState(3);
  const [newsecurity, setnewsecurity] = useState('')
  const handleShowBlock = (event) => {
    let bid = event.currentTarget.id.split('-')[1]
    setShowblock(true);
    setblockid(bid)
  }
  const [tkt, setTkt] = useState('');
  const [tktstatus, setTktstatus] = useState('');
  const [adminreply, setAdminreply] = useState([]);
  const [msg, setMsg] = useState('');
  const [open, setOpen] = useState([]);
  const [close, setClose] = useState([]);
  const [user, setuser] = useState({ 'userdata': [], 'path': '' })
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2 } = useForm();
  const { register: register3, formState: { errors: errors3 }, handleSubmit: handleSubmit3 } = useForm();
  const [reset, setreset] = useState(true)
  const [pwd, setpassword] = useState(false)
  const [email, setemail] = useState(false)
  const [mobile, setmobile] = useState(false)
  const [newmobile, setnewmobile] = useState(false)
  const [newemail, setnewemail] = useState(false)
  const [security, setsecurity] = useState(false)
  const [status, setStatus] = useState({ vstatus: true, lstatus: true, istatus: true, cstatus: true })
  const [faq, setfaq] = useState([])
  const [question, setQuestion] = useState('')
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [qid, setqid] = useState('')
  const [selectedOption, setSelectedOption] = useState(null);
  const [answer, setanswer] = useState(0);
  const [newotp, setnewotp] = useState('');
  const [total, setTotal] = useState([]);
  const [otp, setOtp] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [report, setreport] = useState([])
  const otpNum = [];
  const handleChange = (e) => {
    setOtp(e);
    otpNum.push(e)
    setTotal(otpNum)
  };
  const disAble = () => {
    document.getElementById('resend').disabled = true;
    setTimeout(function () {
      document.getElementById('resend').disabled = false;
    }, 30000)
  }
  const handleSelect = (e) => {
    setSelectedOption(e);
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };
  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };
  const handlePasswordChange = (e) => {
    if (checkStrength(e.target.value) == false) {
      $('#sign-up').attr('disabled', true);
    }
  }
  useEffect(() => {
    if (location.state == "report") {
      setActiveTab("report");
    }
    else {
      setActiveTab("account");
    }
    $(document).ready(function () {

      $('#password').keyup(function () {
        var password = $('#password').val();
        if (checkStrength(password) == false) {
          $('#sign-up').attr('disabled', true);
        }
      });
      $('#confirm-password').blur(function () {
        if ($('#password').val() !== $('#confirm-password').val()) {
          $('#popover-cpassword').removeClass('hide');
          $('#sign-up').attr('disabled', true);
        } else {
          $('#popover-cpassword').addClass('hide');
        }
      });

    });

  }, []);
  const checkStrength = (password) => {
    var strength = 0;

    //If password contains both lower and uppercase characters, increase strength value.
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      strength += 1;
      $('.active_val').addClass('text-success pass_val');
      $('.low-upper-case i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');


    } else {
      $('.active_val').removeClass('text-success pass_val');
      $('.low-upper-case i').addClass('fa-file_text_fail').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    //If it has numbers and characters, increase strength value.
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
      strength += 1;
      $('.active_val1').addClass('text-success pass_val');
      $('.one-number i').removeClass('fa-file_text').addClass('k');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val1').removeClass('text-success pass_val');
      $('.one-number i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    //If it has one special character, increase strength value.
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strength += 1;
      $('.active_val2').addClass('text-success pass_val');
      $('.one-special-char i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val2').removeClass('text-success pass_val');
      $('.one-special-char i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    if (password.length > 7) {
      strength += 1;
      $('.active_val3').addClass('text-success pass_val');
      $('.eight-character i').removeClass('fa-file_text').addClass('');
      $('#popover-password-top').addClass('hide');

    } else {
      $('.active_val3').removeClass('text-success pass_val');
      $('.eight-character i').addClass('fa-file_text').removeClass('');
      $('#popover-password-top').removeClass('hide');
    }

    if (strength < 2) {
      $('#result').removeClass()
      $('#password-strength').addClass('progress-bar-danger');

      $('#result').addClass('text-danger').text('Very Week');
      $('#password-strength').css('width', '10%');
    } else if (strength == 2) {
      $('#result').addClass('good');
      $('#password-strength').removeClass('progress-bar-danger');
      $('#password-strength').addClass('progress-bar-warning');
      $('#result').addClass('text-warning').text('Week')
      $('#password-strength').css('width', '60%');
      return 'Week'
    } else if (strength == 4) {
      $('#result').removeClass()
      $('#result').addClass('strong');
      $('#password-strength').removeClass('progress-bar-warning');
      $('#password-strength').addClass('progress-bar-success');
      $('#result').addClass('text-success pass_val').text('Strength');
      $('#password-strength').css('width', '100%');

      return 'Strong'
    }
  }
  const handleCloselock = () => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('status', '1');
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/lock_unlock`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      if (res.data.status === 1) {
        setlockstatus('1')
        setShowlock(false);
      }
    }).catch((e) => {
      // console.log(e)
    })
  }
  const handleShowlock = () => setShowlock(true);
  const handleCloseunlock = () => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('status', '0');

    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/lock_unlock`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      if (res.data.status === 1) {

        setlockstatus('0')
        setShowunlock(false);
      }
    }).catch((e) => {
      //console.log(e)
    })
  }
  const handleShowunlock = () => setShowunlock(true);
  //Block List
  useEffect(() => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/block_list`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        const proFormData = new FormData();
        proFormData.append('user_id', local_storage.user_id);
        let profile_list = []
        let path;
        if (res.data.data.length >= 1) {
          for (let d in res.data.data) {
            proFormData.append('profile_id', res.data.data[d].user_id)
            axios({
              method: 'POST',
              url: `${baseUrl}/Profiles/profile_view`,
              headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
              },
              data: proFormData
            }).then((response) => {
              if (response.data.status === 1) {
                profile_list.push(response.data.data)
                path = response.data.path;
              }
            }).catch((error) => {
              //  console.log(error)
            })
          }
          setTimeout(() => {
            setValue({ 'items': profile_list, 'path': res.data.path })
          }, 2000);
        }
      }
    }).catch((e) => {
      console.log(e)
    })
    const userFormData = new FormData();
    userFormData.append('user_id', local_storage.user_id);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/user_profile`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: userFormData
    }).then((res) => {
      if (res.data.status === 1) {
        setuser({ 'userdata': res.data.data, 'path': `${res.data.path}` })
      }
    }).catch((e) => {
      //   console.log(e)
    })
    axios({
      method: 'GET',
      url: `${baseUrl}/Profiles/faqs`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': defaultAuth,
      }
    }).then((res) => {
      setfaq(res.data.data)
    }).catch((e) => {
      //  console.log(e.response)

    })
    axios({
      method: 'GET',
      url: `${baseUrl}/Policies/cookie_policy`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': defaultAuth,
      }
    }).then((res) => {
      setCookie(res.data.data[0].description)
    }).catch((e) => {
      console.log(e.response)

    })

    axios({
      method: 'GET',
      url: `${baseUrl}/Policies/terms_use`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': defaultAuth,
      }
    }).then((res) => {
      setTerms(res.data.data[0].description)
    }).catch((e) => {
      console.log(e.response)

    })
  }, [])
  //Privacy Plolicy
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${baseUrl}/Privacy_policy`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': defaultAuth,
      }
    }).then((res) => {
      setPrivacy(res.data.data[0].description)
    }).catch((e) => {
      console.log(e.response)

    })
  }, [])
  //Community Guidelines
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${baseUrl}/Policies/community_guide_line`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': defaultAuth,
      }
    }).then((res) => {
      setGuide(res.data.data[0].description)
    }).catch((e) => {
      console.log(e.response)
    })

  }, [])

  useEffect(() => {

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/notification_settings_status`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      let like;
      let interest;
      let view;
      let chat;
      if (res.data.status === 1) {
        if (res.data.data.like_status == '1') {
          like = true
        } else {
          like = false
        }
        if (res.data.data.view_status == '1') {
          view = true
        } else {
          view = false
        }
        if (res.data.data.interest_status == '1') {
          interest = true
        } else {
          interest = false
        }
        if (res.data.data.chat_status == '1') {
          chat = true
        } else {
          chat = false
        }
        setStatus({ lstatus: like, vstatus: view, istatus: interest, cstatus: chat })
      }
    }).catch((e) => {
      console.log(e, 'e')
    })
  }, [])
  //Logout

  const onLogout = (e) => {
    e.preventDefault();
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/logout`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/login', { state: location.state })
        }, 2000);

        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }
    }).catch((e) => {
      // console.log(e.response, 'e')
    })
  };
  //Delete Account
  const onDeleteAcc = (e) => {
    e.preventDefault();

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/delete_account`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {

      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/login', { state: location.state })
        }, 2000);

        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      // console.log(e.response, 'e')
    })
  };

  const notificationstatus = (event) => {
    let t = event.target.id.split('-')[1]
    let st;
    if (event.target.checked) {
      st = 1
    } else {
      st = 0
    }
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('type', t);
    regFormData.append('status', st);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/notification_settings`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        axios({
          method: 'POST',
          url: `${baseUrl}/Profiles/notification_settings_status`,
          headers: {
            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
            'X-api-key': local_storage.api_key,
          },
          data: regFormData

        }).then((res) => {
          let like;
          let interest;
          let view;
          let chat;
          if (res.data.status === 1) {
            if (res.data.data.like_status == '1') {
              like = true
            } else {
              like = false
            }
            if (res.data.data.view_status == '1') {
              view = true
            } else {
              view = false
            }
            if (res.data.data.interest_status == '1') {
              interest = true
            } else {
              interest = false
            }
            if (res.data.data.chat_status == '1') {
              chat = true
            } else {
              chat = false
            }
            setStatus({ lstatus: like, vstatus: view, istatus: interest, cstatus: chat })

          }

        }).catch((e) => {
          console.log(e, 'e')
        })

      }

    }).catch((e) => {
      console.log(e, 'e')
    })
  }
  useEffect(() => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('type', 1);
    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/security_questions`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key
      },
      data: regFormData
    }).then((res) => {
      setQuestion(res.data.data[0].question)
      setqid(res.data.data[0].question_id)
    }).catch((e) => {
      // console.log(e.response)
    })

    axios({
      // mode: 'no-cors',
      method: 'GET',
      url: `${baseUrl}/Registration/country_code`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': Auth,
      }
    }).then((response) => {
      setcountry(response.data.data)
    }).catch((e) => {
      console.log(e, 'E')

    })

  }, [])

  const setPass = (e) => {
    e.preventDefault();
    let answer = document.getElementById('answer').value
    if (count === 1) {
      setnewsecurity(true);
      setsecurity(false);
    } else {
      setCount(count - 1);
      const regFormData = new FormData();
      regFormData.append('user_id', local_storage.user_id);
      regFormData.append('question_id', qid);
      regFormData.append('answer', answer);
      regFormData.append('type', 2);

      axios({
        method: 'POST',
        url: `${baseUrl}/Registration/question_submit`,

        headers: {
          "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
          'X-api-key': local_storage.api_key,
        },
        data: regFormData

      }).then((res) => {
        if (res.data.status === 1) {
          { setpassword(true) }
          Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
            onClick: function () { } // Callback after click
          }).showToast();
        } else {

          { setsecurity(true) }
          Toastify({
            text: res.data.message,
            duration: 2000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
            onClick: function () { } // Callback after click
          }).showToast();
        }
      }).catch((e) => {
        console.log(e, 'e')
      })
    }
  }

  const newPassword = (data) => {
    let old = document.getElementById('oldpass').value;
    let newp = document.getElementById('newpass').value;
    let confirmPassword = document.getElementById('confirmpassword').value; // Get confirm password value

    // Check if new password and confirm password match
    if (newp !== confirmPassword) {
      Toastify({
        text: "New password and confirm password do not match",
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
          background: "linear-gradient(to right, #D90000, #ff6666)", // Change color to indicate error
        },
      }).showToast();
      return; // Stop form submission if passwords do not match
    }

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('old_password', old);
    regFormData.append('new_password', newp);

    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/change_password`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/login')
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
        }).showToast();
      }

    }).catch((e) => {
      console.log(e, 'e')
    });
  }


  const newEmail = (data) => {
    let new_email = document.getElementById('newemail').value;
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('email', new_email);

    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/change_email`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        localStorage.setItem("newmail", JSON.stringify(new_email))
        setTimeout(() => {
          navigate('/Emailverify')
        }, 2000);

        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }
    }).catch((e) => {
      console.log(e, 'e')
    })
  }

  const newNumber = (data) => {
    if (selectedOption == null) {
      Toastify({
        text: "Please select country code",
        duration: 2000,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        onClick: function () { }
      }).showToast();
      return false;
    }
    let num = document.getElementById('newnum').value;
    let code = document.getElementById('newcode').value;
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('country_code', code);
    regFormData.append('mobile', num);

    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/change_mobile`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key
      },
      data: regFormData
    }).then((res) => {
      if (res.data.status === 1) {
        localStorage.setItem("newnum", JSON.stringify(num));
        setTimeout(() => {
          navigate('/Otp')
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }
    }).catch((e) => {
      console.log(e, 'e')
    })
  }

  const newSecurityQuestion = (data) => {
    let newQuestion = document.getElementById('newq').value
    const regFormData = new FormData();
    regFormData.append('email_mobile', newQuestion);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/forgot_password`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        localStorage.setItem("newq", JSON.stringify(newQuestion))
        setnewotp(true);
        setnewsecurity(false)
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        setnewsecurity(true)
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e, 'e')
    })
  }
  //Block
  const handleBlockapi = event => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('profile_id', blockid)
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/block_user`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        document.getElementById(`b-${blockid}`).classList.add('display2');
        setShowblock(false);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((error) => {
      console.log(error)
    })
  }
  //Contact US
  useEffect(() => {

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('email', local_storage.email);
    regFormData.append('type', '0');
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/report_reply_data`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data?.data) {
        setOpen(res.data.data)
      }


    }).catch((e) => {
      console.log(e, 'e')
    })
  }, [])
  useEffect(() => {

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('email', local_storage.email);
    regFormData.append('type', '1');
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/report_reply_data`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data?.data) {
        setClose(res.data.data)
      }


    }).catch((e) => {
      console.log(e, 'e')
    })
  }, [])
  const onBack = () => {
    document.getElementById('report_data').style.display = "block";
    document.getElementById('chat_data').style.display = "none";
  }
  const onReportReply = (report_id) => {
    document.getElementById('report_data').style.display = "none";
    document.getElementById('chat_data').style.display = "block";
    setTkt(report_id);
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('report_id', report_id);


    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/report_messages`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      setTktstatus(res.data.status);
      setAdminreply(res.data.data);
    }).catch((e) => {
      console.log(e, 'e');
    })
  }
  const msgsend = () => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    regFormData.append('report_id', tkt);
    regFormData.append('message', msg);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/report_reply`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      setMsg('');
      if (res.data.status === 1) {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
        // Call onReportReply after successful message send
        if (res.data.status === 1) {
          onReportReply(tkt);  // Passing the report_id (tkt) to onReportReply
        }
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { }
        }).showToast();
      }

    }).catch((e) => {
      console.log(e, 'e')
    })

  }
  const submitOtp = (e) => {
    e.preventDefault();
    if (total === '') {
      alert('please enter otp')
      return false;
    }
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id)
    regFormData.append('otp', total);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/verify_otp`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/Security', { state: location.state })
        }, 2500);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      console.log(e.response, 'e')

    })

  };

  const resendOtp = (e) => {
    e.preventDefault()

    setOtp(0)

    const resendFormData = new FormData();

    resendFormData.append('user_id', local_storage.user_id)

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/resend_otp`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: resendFormData
    }).then((res) => {
      Toastify({
        text: res.data.message,
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
        onClick: function () { } // Callback after click
      }).showToast();
      disAble();
    }).catch((e) => {
      console.log(e.data.data, 'REEEE')
    })

  }
  let Flagurl = `${flagUrl}`
  let newdata = country.map((item, i) => {
    return { value: item.country_name, text: `${item.country_name + ' + ' + item.code}`, code: item.code, icon: <img src={Flagurl + '/' + item.country_flag} width='18px' /> }
  })

  let block_list = value.items.map((item) =>
    <div className="block-card shadow mb-3" id={`b-${item.user_id}`} style={{ cursor: 'pointer' }}>
      <p variant="primary" onClick={handleShowBlock} id={`getuser-${item.user_id}`} style={{ display: 'contents' }}>
        <div className="d-flex align-items-end">
          <img src={`${item.selfie_status == '1' ? value.path + '/' + item.images[0].image : item.gender == '1' ? male : female}`}></img>
          <p>{item.display_name}</p></div>
        <div>
          <Button className="up-btn">
            unblock
          </Button></div>
      </p>
    </div>
  )

  const [menuOption, setMenuOption] = useState(true)
  const menuOpen = () => {
    setMenuOption(!menuOption)
  }
  //console.log(value)
  return (
    <div className="d-lg-flex d-block">
      <div className="col-3 page_left" style={{ width: 'auto' }}>
        <Sidemenu />
      </div>
      <div className="col-9 d-flex flex-column settings-page page_right">
        <div className="d-flex flex-row bg-white top-bar shadow">
          <img src={img} className="w-50 site_logo d-block m-auto inner-logo" />
        </div>
        <div className="d-lg-none d-block p-0 m-0">
          {menuOption ?
          <div className="user_info col-lg-7 col-12 mt-4 mb-3">
            <div className="Tabs">
              <ul className="nav">
                <li>
                  <TabNavItem active title="Account Settings" id="account" activeTab={activeTab} setActiveTab={setActiveTab} menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Partner Preferences" id="partner-preference" activeTab={activeTab} setActiveTab={setActiveTab} menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Reset Personal Details" id="personal-details" activeTab={activeTab} setActiveTab={setActiveTab} menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Notification Settings" onClick={notificationstatus} id="notification" activeTab={activeTab} setActiveTab={setActiveTab} menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Blocked Profiles" id="block" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="FAQ" id="faq" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Report" id="report" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Terms of use" id="terms" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Privacy policy" id="privacy" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Community guidelines" id="community" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
              </ul>
              <div className="d-lg-block d-flex">
                <Button variant="primary" onClick={handleshowdelete} className="settings_logout">
                  Delete Account
                </Button><br></br>
                <Button variant="primary" onClick={handleShow} className="settings_logout">
                  Logout
                </Button>
              </div>
            </div>
          </div> :
          <div className="col-lg-5 col-12 bg-white" style={{ height: '100vh' }}>
            <button className="close d-lg-none d-block" onClick={menuOpen}><WidgetsIcon /> Menu</button>
            <div className="outlet">
              <TabContent id="account" activeTab={activeTab}>
                <div className="account">
                  <p style={{ color: "#2d2d2d" }}>Photos</p>
                  <a className="p-0 m-0" href="/addphotos"><img src={`${user.path}${user.userdata.selfie_pic}`} style={{ width: '80px', height: '80px', objectFit: 'contain', backgroundColor: "#50555c" }}></img></a>
                  <p>About</p>
                  <a className="p-0 m-0" href="/About"><h5>{user.userdata.about_me}</h5></a>
                  <p>Dispaly Name</p>
                  <a className="p-0 m-0" href="/Displayname"><h5>{user.userdata.display_name}</h5></a>
                  <p>Date Of Birth</p>
                  <a className="p-0 m-0" href="/Displayname"><h5>{user.userdata.dob}</h5></a>
                  <a className="p-0 m-0 account_card mt-4" href="/Moreabout">
                    <p>
                      More about me
                    </p>
                  </a>
                  <a className="p-0 m-0 account_card mt-4" href="/Hobbies">
                    <p>
                      My Hobbies and Interests
                    </p>
                  </a>
                </div>
              </TabContent>
              <TabContent id="partner-preference" activeTab={activeTab}>
                <h5 className="text-center mb-5 mt-5"> Change your Partner Preferences</h5> <Button className="data_btn w-50" variant="danger" onClick={() => navigate('/Lookingfor')} >Change</Button>
              </TabContent>
              <TabContent id="personal-details" activeTab={activeTab}>
                <div>
                  {
                    pwd ?
                      <div>
                        <img src={arrow} onClick={() => { setpassword(false); setsecurity(true) }} style={{ cursor: 'pointer' }} className="mb-3" />
                        <Form onSubmit={handleSubmit2(newPassword)}>
                          <Form.Group className="mb-3" >
                            <h5>Change Password</h5>
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control {...register2("oldpassword", { required: true })} type={passwordShown2 ? "text" : "password"} className="pass_field" id="oldpass" />
                            <span className="passtext-2" onClick={togglePassword2}>{passwordShown2 ? "hide" : "show"}</span>
                            <span className='error_msg'> {errors2.oldpassword?.type === 'required' && "Please enter old password"}</span>
                          </Form.Group>
                          <Form.Group className="mb-1" >
                            <Form.Label>New Password</Form.Label>

                            <Form.Control
                              {...register2("newpassword", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message: "Password must be at least 8 characters"
                                },
                                pattern: {
                                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                  message: "Password must contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character"
                                }
                              })}
                              type={passwordShown ? "text" : "password"}
                              className="pass_field"
                              id="newpass"
                              onChange={handlePasswordChange}
                            />
                            <span className="passtext-1" onClick={togglePassword}>{passwordShown ? "hide" : "show"}</span>
                            <span className='error_msg'> {errors2.newpassword?.type === 'required' && "Please enter new password"}</span>
                            <span className='error_msg'> {errors2.newpassword?.type === 'minLength' && "Password must be at least 8 characters"}</span>
                            <span className='error_msg'> {errors2.newpassword?.type === 'pattern' && "Password should be 8 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>

                            <ul className="list-unstyled password_val">
                              <li className="active_val3 ch_ps"><span className="eight-character"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; 8+ Character</li>
                              <li className="active_val2 ch_ps"><span className="one-special-char"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;# Special </li>
                              <li className="active_val ch_ps"><span className="low-upper-case"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; Aa Alphabet</li>
                              <li className="active_val1 ch_ps"><span className="one-number"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;1 number</li>
                            </ul>

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              {...register2("confirmpassword", { required: "Please confirm your password" })}
                              type={passwordShown1 ? "text" : "password"}
                              className="pass_field"
                              id="confirmpassword" // Set the id for easy retrieval
                            />
                            <span className="passtext-2" onClick={togglePassword1}>{passwordShown1 ? "hide" : "show"}</span>
                            <span className='error_msg'> {errors2.confirmpassword?.type === 'required' && "Please enter confirm password"}</span>
                          </Form.Group>
                          <button
                            type="submit"
                            //  onClick={(e) => {changePassword(e)}} 
                            className="btn btn-dark mt-3 btn_disabled m-auto d-block ">CHANGE</button>
                        </Form>
                      </div> :
                      email ?
                        <div>
                          <img src={arrow} onClick={() => { setemail(false); setreset(true) }} style={{ cursor: 'pointer' }} className="mb-3" />
                          <h6>Email</h6>
                          <span>Current Email Address</span>
                          <Form.Control value={user.userdata.email} className="form-control mt-4" />
                          <span className="pass_text" onClick={() => { setnewemail(true); setemail(false) }}>Edit</span>
                        </div> :
                        newemail ?
                          <div>
                            <img src={arrow} onClick={() => { setemail(true); setnewemail(false) }} style={{ cursor: 'pointer' }} className="mb-3" />
                            <h5>Email</h5>
                            <p>New Email Address</p>
                            <Form.Control className="form-control mt-4" id="newemail" />
                            <Button variant="primary" onClick={(e) => { newEmail(e) }} className="reset_btn w-50">
                              SUBMIT
                            </Button>
                          </div> :
                          mobile ?
                            <div>
                              <img src={arrow} onClick={() => { setmobile(false); setreset(true) }} style={{ cursor: 'pointer' }} className="mb-3" />

                              <h6>Mobile Number</h6>
                              <span>Current Mobile Number</span>
                              <Form.Control value={user.userdata.mobile} className="form-control mt-4" />
                              <span className="pass_text" onClick={() => { setnewmobile(true); setmobile(false) }}>Edit</span>
                            </div> :
                            newmobile ?
                              <div>
                                <img src={arrow} onClick={() => { setmobile(true); setnewmobile(false) }} style={{ cursor: 'pointer' }} className="mb-3" />
                                <h5>Mobile Number</h5>
                                <p>New Mobile Number</p>
                                <Form onSubmit={handleSubmit3(newNumber)}>
                                  <Form.Group className="mb-3" >
                                    <div className="country_code">
                                      <Select
                                        // {...register("code", { required: true })}
                                        className="select_country col-3 w-auto"
                                        components={{ IndicatorSeparator: () => null }}
                                        placeholder={<p className="select_placeholder">IN +91</p>}
                                        value={selectedOption}
                                        options={newdata}
                                        onChange={handleSelect}
                                        defaultCountryCode="+91"
                                        id="newcode"
                                        getOptionLabel={e => (
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {e.icon}
                                            <span style={{ marginLeft: 5 }}>{e.code}</span>
                                          </div>
                                        )}
                                      />
                                      <Form.Control {...register3("mobile", { required: true, minLength: 10, maxLength: 15 })} type="number" placeholder="Enter your mobile number" className="col-9 number_field" id="newnum" />
                                    </div>
                                    {errors3.mobile?.type === 'required' && <span className="error_msg_mob">Please enter Valid Mobile number</span>}
                                    <span className='error_msg_mob'> {errors3.mobile?.type === 'maxLength' && "mobile number should be 10-15 numbers"}</span>
                                    <span className='error_msg_mob'> {errors3.mobile?.type === 'minLength' && "mobile number should be 10-15 numbers"}</span>
                                  </Form.Group>
                                  <Button variant="primary" type="submit" className="reset_btn w-50 mt-4">
                                    SUBMIT
                                  </Button>
                                </Form>
                              </div> :
                              security ?
                                <div>
                                  <img src={arrow} onClick={() => { setsecurity(false); setreset(true) }} style={{ cursor: 'pointer' }} className="mb-3" />
                                  <h5>security question</h5>
                                  <p>This if you forget your password, your security question helps establish that own your account.</p>
                                  <h5>Question</h5>
                                  <p>{question}</p>
                                  <Form.Control className="form-control mt-4" id='answer' />
                                  {count != 3 ? <span style={{ color: '#D90000' }}>Answer is incorrect you have {count} attempt. So please reset the security question</span> : ''}
                                  {/* <Button variant="primary" type="submit" className="reset_btn w-50" onClick={()=>{setpassword(true);setsecurity(false)}}>
                  SUBMIT
                </Button> */}
                                  <Button variant="primary" type="submit" className="reset_btn w-50" onClick={(e) => { setPass(e) }}>
                                    SUBMIT
                                  </Button>
                                </div> :
                                newsecurity ?
                                  <div className="text-center p-2">
                                    <img src={icon} />
                                    <h5 className="mt-3">Rajakirani</h5>
                                    <Form.Control className="form-control mt-4" placeholder="Email / Mobile number" id="newq" />
                                    <Button variant="primary" type="submit" className="reset_btn w-50 mt-5" onClick={(e) => { newSecurityQuestion(e) }}>
                                      SUBMIT
                                    </Button>
                                  </div> :
                                  newotp ?
                                    <div className="text-center p-2">
                                      <div className="mt-5">

                                        <div className="text-center">
                                          <img src={icon} />
                                          <h5 className="mt-5">My Code</h5>
                                          <p className="m-4">{newQuestion}</p>
                                          <div className="otp_input">
                                            <OtpInput
                                              inputStyle={{
                                                width: '2rem',
                                                height: '2rem',
                                                margin: '10px 10px',
                                                fontSize: '1rem',
                                                border: 'none',
                                                borderBottom: '1px solid',
                                              }}
                                              isInputNum='true'
                                              maxLength='6'
                                              numInputs={6}
                                              value={otp}
                                              onChange={handleChange}
                                              separator={<span></span>}
                                            />
                                          </div>
                                          <button disabled={otp.length !== 6 || disabled} type="submit" onClick={submitOtp} className="btn btn-dark w-50 mt-4 btn_disabled">CONTINUE</button><br></br>
                                          <Button variant="light" onClick={(e) => resendOtp(e)} className="mt-4 resend_btn" id='resend'>RESEND CODE</Button>

                                        </div>
                                      </div>
                                    </div> :
                                    reset ?
                                      <div>
                                        <button onClick={() => { setsecurity(true); setreset(false) }} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none', border: '0px', margin: '5px', padding: '5px', borderRadius: '5px' }}>Change Password</button>
                                        <button onClick={() => { setemail(true); setreset(false) }} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none', border: '0px', margin: '5px', padding: '5px', borderRadius: '5px' }}>Change Email</button>
                                        <button onClick={() => { setmobile(true); setreset(false) }} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none', border: '0px', margin: '5px', padding: '5px', borderRadius: '5px' }}>Change Mobile Number</button>
                                      </div> :
                                      ""
                  }
                </div>
              </TabContent>
              <TabContent id="profile-lock" activeTab={activeTab}>
                {lock_status == '0' ?
                  <div className="profile-lock">
                    <img src={lockuser} />
                    <img src={`${user.path}${user.userdata.selfie_pic}`} style={{ width: '80px', height: '80px', borderRadius: '50px' }} />
                    <h5>You Unlocked your profile</h5>
                    <p>Get access to chat , calls and video calls</p>

                    <Button1 variant="primary" onClick={handleShowlock}>
                      LOCK YOUR PROFILE
                    </Button1>

                    <Modal show={showlock} animation={false}>

                      <Modal.Body className="m-auto text-center py-5">
                        <img src={lock}></img>
                        <h5>You Locked your profile</h5>
                        <p>Get Access to chat, calls and video calls</p>
                        <Button1 variant="primary" onClick={handleCloselock} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none' }}>
                          OK
                        </Button1>
                      </Modal.Body>
                      <Modal.Footer>


                      </Modal.Footer>
                    </Modal>
                  </div> :
                  <div className="profile-lock">
                    <img src={lock} />
                    <img src={`${user.path}${user.userdata.selfie_pic}`} style={{ width: '80px', height: '80px', borderRadius: '50px' }} />

                    <h5>You Locked your profile</h5>
                    <p>Get access to chat , calls and video calls</p>

                    <Button1 variant="primary" onClick={handleShowunlock}>
                      UNLOCK YOUR PROFILE
                    </Button1>

                    <Modal show={showunlock} animation={false}>

                      <Modal.Body className="m-auto text-center py-5">
                        <img src={unlock}></img>
                        <h5>You Unlocked your profile</h5>
                        <p>Get Access to chat, calls and video calls</p>
                        <Button1 variant="primary" onClick={handleCloseunlock} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none' }}>
                          OK
                        </Button1>
                      </Modal.Body>
                      <Modal.Footer>

                      </Modal.Footer>
                    </Modal>
                  </div>
                }
              </TabContent>
              <TabContent id="block" activeTab={activeTab}>
                {value.items.length > 0 ? block_list : <p className="text-center"><b>No Blocked Profiles</b></p>}
              </TabContent>
              <TabContent id="notification" activeTab={activeTab} >
                <div>
                  <div className="single-notification mt-5" >
                    <p>Liked Profile</p>
                    <label className="switch">
                      <input type="checkbox" id='like-1' checked={status.lstatus} onChange={notificationstatus}></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="single-notification">
                    <p>Views</p>
                    <label className="switch">
                      <input type="checkbox" id='view-3' checked={status.vstatus} onChange={notificationstatus}></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="single-notification">
                    <p>Requests</p>
                    <label className="switch">
                      <input type="checkbox" id='interest-2' checked={status.istatus} onChange={notificationstatus}></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="single-notification">
                    <p>Chat</p>
                    <label className="switch">
                      <input type="checkbox" id='chat-4' checked={status.cstatus} onChange={notificationstatus}></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </TabContent>
              <TabContent id="faq" activeTab={activeTab}>
                {faq.map((item) =>
                  <>
                    <div className="settings_card">
                      <p><b>{item.question}</b><br></br>{item.answer}</p>
                    </div>
                  </>
                )}

              </TabContent>
              <TabContent id="report" activeTab={activeTab}>
                <div className="contacts" id="report_data">
                  <h5 className="text-center" style={{ color: '#D90000' }}>Reports</h5>
                  <hr style={{ color: 'black' }}></hr>


                  <Tabs className="reports_list">
                    <Tab label="Open Reports">

                      <div className="mt-3">
                        {
                          open.length > 0 ? (
                            open.map(reports => (
                              <div key={reports.report_id}>
                                <button className="card-button"
                                  onClick={() => { onReportReply(reports.report_id) }}
                                >
                                  <Card className="notifications_card mb-3">
                                    <CardHeader style={{ width: '20%' }}>
                                      <img src={Admin} className="my-2" />
                                    </CardHeader>
                                    <Card.Body>
                                      <Card.Text>
                                        <p className="m-0 p-0" style={{ fontWeight: 700, color: '#1F2024', fontSize: '15px' }}> {reports.name} </p>
                                        <p className="m-0 p-0" style={{ color: '#71727A', fontSize: '12px' }}>  </p>

                                      </Card.Text>
                                    </Card.Body>

                                  </Card>
                                </button>
                              </div>

                            ))) : <p className="text-center">No Open Reports</p>
                        }

                      </div>

                    </Tab>
                    <Tab label="Closed Reports">
                      <div className="mt-3">
                        {
                          close.length > 0 ? (
                            close.map(reports => (
                              <div key={reports.report_id}>
                                <button className="card-button"
                                  onClick={() => { onReportReply(reports.report_id) }}
                                >
                                  <Card className="notifications_card mb-3">
                                    <CardHeader style={{ width: '20%' }}>
                                      <img src={Admin} className="my-2" />
                                    </CardHeader>
                                    <Card.Body>
                                      <Card.Text>
                                        <p className="m-0 p-0" style={{ fontWeight: 700, color: '#1F2024', fontSize: '15px' }}> {reports.name} </p>
                                        <p className="m-0 p-0" style={{ color: '#71727A', fontSize: '12px' }}>  </p>

                                      </Card.Text>
                                    </Card.Body>

                                  </Card>
                                </button>
                              </div>

                            ))) : <p className="text-center">No Closed Reports</p>
                        }
                      </div>
                    </Tab>
                  </Tabs>



                </div>
                <div style={{ display: 'none' }} id="chat_data">
                  {adminreply && adminreply.length > 0 &&
                    <div>
                      <div className="d-flex align-items-center">
                        <img src={arrow} onClick={() => onBack()} style={{ cursor: 'pointer' }} />
                        <img src={Admin} style={{ width: '10%', marginLeft: '15px' }} />
                        <h4 className="text-center m-0 px-2" style={{ color: '#ff4545' }}>Admin</h4>
                      </div>
                      <hr style={{ color: 'black' }}></hr>
                    </div>
                  }
                  <div style={{ height: '400px', overflow: 'auto' }}>
                    {adminreply ? (
                      adminreply.map(replies => (
                        <div>
                          {replies.send_by === '1' ?
                            <div className='d-flex align-items-center justify-content-end'>
                              <div className='msg sent'>
                                <p> {replies.msg} </p>
                              </div>
                              <div className="avatar"> U </div>
                            </div> :
                            <div className='d-flex align-items-center justify-content-start'>
                              <div className="avatar"> A </div>
                              <div className='msg received'>
                                <p> {replies.msg} </p>
                              </div>
                            </div>}
                        </div>
                      )))
                      : ""}
                  </div>
                  {adminreply && adminreply.length > 0 &&
                    <div className="sendMsg_2">
                      <Input onChange={(e) => { setMsg(e.target.value) }} style={{ width: '100%', fontSize: '15px', fontWeight: '550' }}
                        placeholder='Type message...' type="text" id="msg_input" pattern="^[a-zA-Z][\sa-zA-Z]*" value={msg} />
                      <Button type="submit" className="chat_send" >
                        <img src={send_btn} onClick={msgsend} />
                      </Button>
                    </div>}
                </div>
              </TabContent>
            </div>
            <div style={{ marginTop: '-5rem' }}>
              <TabContent className="links" id="terms" activeTab={activeTab}>
                <iframe src={`${footerUrl}/terms_and_conditions`} style={{ width: '100%', height: '90vh' }} >
                </iframe>
              </TabContent>
              <TabContent id="privacy" activeTab={activeTab}>
                <iframe src={`${footerUrl}/privacy_policy`} style={{ width: '100%', height: '90vh' }} >
                </iframe>
              </TabContent>
              <TabContent id="community" activeTab={activeTab}>
                <iframe src={`${footerUrl}/community_guidelines`} style={{ width: '100%', height: '90vh' }} >
                </iframe>
              </TabContent>
              <TabContent id="cookie" activeTab={activeTab}>
                {Cookie}
              </TabContent>
              <TabContent id="delete_account" activeTab={activeTab}>
              </TabContent>
              <TabContent id="logout" activeTab={activeTab}>
              </TabContent>
            </div>

            <Modal show={showdelete} size="lg" onHide={handleClosedelete} animation={false} aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton className="clse_btn">

              </Modal.Header>
              <Modal.Body className="text-center">Are you sure you want to delete your account? Deleting your account will remove all your profile data, photos, messages, and matches from our system.</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClosedelete}>
                  No
                </Button>
                <Button onClick={(e) => onDeleteAcc(e)} className="up-btn">
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show} size="lg" onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton className="clse_btn">

              </Modal.Header>
              <Modal.Body className="text-center">Are you sure want to logout</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  No
                </Button>
                <Button onClick={(e) => onLogout(e)} className="up-btn">
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>


            <Modal show={showblock} size="lg" onHide={handleCloseblock} animation={false} aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Footer>
                <p className="mt-3 mb-3">Are you sure want to unblock this profile?</p>
                <Button className="up-btn" onClick={handleBlockapi}>
                  Yes
                </Button>
                <Button variant="secondary" onClick={handleCloseblock}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>}
        </div>

        <div className="d-lg-flex d-none p-0 m-0">
          
          <div className="user_info col-lg-7 col-12 mt-4 mb-3">
            <div className="Tabs">
              <ul className="nav">
                <li>
                  <TabNavItem active title="Account Settings" id="account" activeTab={activeTab} setActiveTab={setActiveTab} menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Partner Preferences" id="partner-preference" activeTab={activeTab} setActiveTab={setActiveTab} menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Reset Personal Details" id="personal-details" activeTab={activeTab} setActiveTab={setActiveTab} menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Notification Settings" onClick={notificationstatus} id="notification" activeTab={activeTab} setActiveTab={setActiveTab} menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Blocked Profiles" id="block" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="FAQ" id="faq" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Report" id="report" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Terms of use" id="terms" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Privacy policy" id="privacy" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
                <li>
                  <TabNavItem title="Community guidelines" id="community" activeTab={activeTab} setActiveTab={setActiveTab}
                  menuOption={menuOption}  setMenuOption={setMenuOption} />
                </li>
              </ul>
              <div className="d-lg-block d-flex">
                <Button variant="primary" onClick={handleshowdelete} className="settings_logout">
                  Delete Account
                </Button><br></br>
                <Button variant="primary" onClick={handleShow} className="settings_logout">
                  Logout
                </Button>
              </div>
            </div>
          </div> 
          <div className="col-lg-5 col-12 bg-white" style={{ height: '100vh' }}>
            <button className="close d-lg-none d-block" onClick={menuOpen}><WidgetsIcon /> Menu</button>
            <div className="outlet">
              <TabContent id="account" activeTab={activeTab}>
                <div className="account">
                  <p style={{ color: "#2d2d2d" }}>Photos</p>
                  <a className="p-0 m-0" href="/addphotos"><img src={`${user.path}${user.userdata.selfie_pic}`} style={{ width: '80px', height: '80px', objectFit: 'contain', backgroundColor: "#50555c" }}></img></a>
                  <p>About</p>
                  <a className="p-0 m-0" href="/About"><h5>{user.userdata.about_me}</h5></a>
                  <p>Dispaly Name</p>
                  <a className="p-0 m-0" href="/Displayname"><h5>{user.userdata.display_name}</h5></a>
                  <p>Date Of Birth</p>
                  <a className="p-0 m-0" href="/Displayname"><h5>{user.userdata.dob}</h5></a>
                  <a className="p-0 m-0 account_card mt-4" href="/Moreabout">
                    <p>
                      More about me
                    </p>
                  </a>
                  <a className="p-0 m-0 account_card mt-4" href="/Hobbies">
                    <p>
                      My Hobbies and Interests
                    </p>
                  </a>
                </div>
              </TabContent>
              <TabContent id="partner-preference" activeTab={activeTab}>
                <h5 className="text-center mb-5 mt-5"> Change your Partner Preferences</h5> <Button className="data_btn w-50" variant="danger" onClick={() => navigate('/Lookingfor')} >Change</Button>
              </TabContent>
              <TabContent id="personal-details" activeTab={activeTab}>
                <div>
                  {
                    pwd ?
                      <div>
                        <img src={arrow} onClick={() => { setpassword(false); setsecurity(true) }} style={{ cursor: 'pointer' }} className="mb-3" />
                        <Form onSubmit={handleSubmit2(newPassword)}>
                          <Form.Group className="mb-3" >
                            <h5>Change Password</h5>
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control {...register2("oldpassword", { required: true })} type={passwordShown2 ? "text" : "password"} className="pass_field" id="oldpass" />
                            <span className="passtext-2" onClick={togglePassword2}>{passwordShown2 ? "hide" : "show"}</span>
                            <span className='error_msg'> {errors2.oldpassword?.type === 'required' && "Please enter old password"}</span>
                          </Form.Group>
                          <Form.Group className="mb-1" >
                            <Form.Label>New Password</Form.Label>

                            <Form.Control
                              {...register2("newpassword", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message: "Password must be at least 8 characters"
                                },
                                pattern: {
                                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                  message: "Password must contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character"
                                }
                              })}
                              type={passwordShown ? "text" : "password"}
                              className="pass_field"
                              id="newpass"
                              onChange={handlePasswordChange}
                            />
                            <span className="passtext-1" onClick={togglePassword}>{passwordShown ? "hide" : "show"}</span>
                            <span className='error_msg'> {errors2.newpassword?.type === 'required' && "Please enter new password"}</span>
                            <span className='error_msg'> {errors2.newpassword?.type === 'minLength' && "Password must be at least 8 characters"}</span>
                            <span className='error_msg'> {errors2.newpassword?.type === 'pattern' && "Password should be 8 characters with atleast 1 upper, 1 lower, 1 numeric and 1 special character"}</span>

                            <ul className="list-unstyled password_val">
                              <li className="active_val3 ch_ps"><span className="eight-character"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; 8+ Character</li>
                              <li className="active_val2 ch_ps"><span className="one-special-char"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;# Special </li>
                              <li className="active_val ch_ps"><span className="low-upper-case"><i className="fa fa-file_text" aria-hidden="true"></i></span>&nbsp; Aa Alphabet</li>
                              <li className="active_val1 ch_ps"><span className="one-number"><i className="fa fa-file_text" aria-hidden="true"></i></span> &nbsp;1 number</li>
                            </ul>

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              {...register2("confirmpassword", { required: "Please confirm your password" })}
                              type={passwordShown1 ? "text" : "password"}
                              className="pass_field"
                              id="confirmpassword" // Set the id for easy retrieval
                            />
                            <span className="passtext-2" onClick={togglePassword1}>{passwordShown1 ? "hide" : "show"}</span>
                            <span className='error_msg'> {errors2.confirmpassword?.type === 'required' && "Please enter confirm password"}</span>
                          </Form.Group>
                          <button
                            type="submit"
                            //  onClick={(e) => {changePassword(e)}} 
                            className="btn btn-dark mt-3 btn_disabled m-auto d-block ">CHANGE</button>
                        </Form>
                      </div> :
                      email ?
                        <div>
                          <img src={arrow} onClick={() => { setemail(false); setreset(true) }} style={{ cursor: 'pointer' }} className="mb-3" />
                          <h6>Email</h6>
                          <span>Current Email Address</span>
                          <Form.Control value={user.userdata.email} className="form-control mt-4" />
                          <span className="pass_text" onClick={() => { setnewemail(true); setemail(false) }}>Edit</span>
                        </div> :
                        newemail ?
                          <div>
                            <img src={arrow} onClick={() => { setemail(true); setnewemail(false) }} style={{ cursor: 'pointer' }} className="mb-3" />
                            <h5>Email</h5>
                            <p>New Email Address</p>
                            <Form.Control className="form-control mt-4" id="newemail" />
                            <Button variant="primary" onClick={(e) => { newEmail(e) }} className="reset_btn w-50">
                              SUBMIT
                            </Button>
                          </div> :
                          mobile ?
                            <div>
                              <img src={arrow} onClick={() => { setmobile(false); setreset(true) }} style={{ cursor: 'pointer' }} className="mb-3" />

                              <h6>Mobile Number</h6>
                              <span>Current Mobile Number</span>
                              <Form.Control value={user.userdata.mobile} className="form-control mt-4" />
                              <span className="pass_text" onClick={() => { setnewmobile(true); setmobile(false) }}>Edit</span>
                            </div> :
                            newmobile ?
                              <div>
                                <img src={arrow} onClick={() => { setmobile(true); setnewmobile(false) }} style={{ cursor: 'pointer' }} className="mb-3" />
                                <h5>Mobile Number</h5>
                                <p>New Mobile Number</p>
                                <Form onSubmit={handleSubmit3(newNumber)}>
                                  <Form.Group className="mb-3" >
                                    <div className="country_code">
                                      <Select
                                        // {...register("code", { required: true })}
                                        className="select_country col-3 w-auto"
                                        components={{ IndicatorSeparator: () => null }}
                                        placeholder={<p className="select_placeholder">IN +91</p>}
                                        value={selectedOption}
                                        options={newdata}
                                        onChange={handleSelect}
                                        defaultCountryCode="+91"
                                        id="newcode"
                                        getOptionLabel={e => (
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {e.icon}
                                            <span style={{ marginLeft: 5 }}>{e.code}</span>
                                          </div>
                                        )}
                                      />
                                      <Form.Control {...register3("mobile", { required: true, minLength: 10, maxLength: 15 })} type="number" placeholder="Enter your mobile number" className="col-9 number_field" id="newnum" />
                                    </div>
                                    {errors3.mobile?.type === 'required' && <span className="error_msg_mob">Please enter Valid Mobile number</span>}
                                    <span className='error_msg_mob'> {errors3.mobile?.type === 'maxLength' && "mobile number should be 10-15 numbers"}</span>
                                    <span className='error_msg_mob'> {errors3.mobile?.type === 'minLength' && "mobile number should be 10-15 numbers"}</span>
                                  </Form.Group>
                                  <Button variant="primary" type="submit" className="reset_btn w-50 mt-4">
                                    SUBMIT
                                  </Button>
                                </Form>
                              </div> :
                              security ?
                                <div>
                                  <img src={arrow} onClick={() => { setsecurity(false); setreset(true) }} style={{ cursor: 'pointer' }} className="mb-3" />
                                  <h5>security question</h5>
                                  <p>This if you forget your password, your security question helps establish that own your account.</p>
                                  <h5>Question</h5>
                                  <p>{question}</p>
                                  <Form.Control className="form-control mt-4" id='answer' />
                                  {count != 3 ? <span style={{ color: '#D90000' }}>Answer is incorrect you have {count} attempt. So please reset the security question</span> : ''}
                                  {/* <Button variant="primary" type="submit" className="reset_btn w-50" onClick={()=>{setpassword(true);setsecurity(false)}}>
                  SUBMIT
                </Button> */}
                                  <Button variant="primary" type="submit" className="reset_btn w-50" onClick={(e) => { setPass(e) }}>
                                    SUBMIT
                                  </Button>
                                </div> :
                                newsecurity ?
                                  <div className="text-center p-2">
                                    <img src={icon} />
                                    <h5 className="mt-3">Rajakirani</h5>
                                    <Form.Control className="form-control mt-4" placeholder="Email / Mobile number" id="newq" />
                                    <Button variant="primary" type="submit" className="reset_btn w-50 mt-5" onClick={(e) => { newSecurityQuestion(e) }}>
                                      SUBMIT
                                    </Button>
                                  </div> :
                                  newotp ?
                                    <div className="text-center p-2">
                                      <div className="mt-5">

                                        <div className="text-center">
                                          <img src={icon} />
                                          <h5 className="mt-5">My Code</h5>
                                          <p className="m-4">{newQuestion}</p>
                                          <div className="otp_input">
                                            <OtpInput
                                              inputStyle={{
                                                width: '2rem',
                                                height: '2rem',
                                                margin: '10px 10px',
                                                fontSize: '1rem',
                                                border: 'none',
                                                borderBottom: '1px solid',
                                              }}
                                              isInputNum='true'
                                              maxLength='6'
                                              numInputs={6}
                                              value={otp}
                                              onChange={handleChange}
                                              separator={<span></span>}
                                            />
                                          </div>
                                          <button disabled={otp.length !== 6 || disabled} type="submit" onClick={submitOtp} className="btn btn-dark w-50 mt-4 btn_disabled">CONTINUE</button><br></br>
                                          <Button variant="light" onClick={(e) => resendOtp(e)} className="mt-4 resend_btn" id='resend'>RESEND CODE</Button>

                                        </div>
                                      </div>
                                    </div> :
                                    reset ?
                                      <div>
                                        <button onClick={() => { setsecurity(true); setreset(false) }} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none', border: '0px', margin: '5px', padding: '5px', borderRadius: '5px' }}>Change Password</button>
                                        <button onClick={() => { setemail(true); setreset(false) }} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none', border: '0px', margin: '5px', padding: '5px', borderRadius: '5px' }}>Change Email</button>
                                        <button onClick={() => { setmobile(true); setreset(false) }} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none', border: '0px', margin: '5px', padding: '5px', borderRadius: '5px' }}>Change Mobile Number</button>
                                      </div> :
                                      ""
                  }
                </div>
              </TabContent>
              <TabContent id="profile-lock" activeTab={activeTab}>
                {lock_status == '0' ?
                  <div className="profile-lock">
                    <img src={lockuser} />
                    <img src={`${user.path}${user.userdata.selfie_pic}`} style={{ width: '80px', height: '80px', borderRadius: '50px' }} />
                    <h5>You Unlocked your profile</h5>
                    <p>Get access to chat , calls and video calls</p>

                    <Button1 variant="primary" onClick={handleShowlock}>
                      LOCK YOUR PROFILE
                    </Button1>

                    <Modal show={showlock} animation={false}>

                      <Modal.Body className="m-auto text-center py-5">
                        <img src={lock}></img>
                        <h5>You Locked your profile</h5>
                        <p>Get Access to chat, calls and video calls</p>
                        <Button1 variant="primary" onClick={handleCloselock} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none' }}>
                          OK
                        </Button1>
                      </Modal.Body>
                      <Modal.Footer>


                      </Modal.Footer>
                    </Modal>
                  </div> :
                  <div className="profile-lock">
                    <img src={lock} />
                    <img src={`${user.path}${user.userdata.selfie_pic}`} style={{ width: '80px', height: '80px', borderRadius: '50px' }} />

                    <h5>You Locked your profile</h5>
                    <p>Get access to chat , calls and video calls</p>

                    <Button1 variant="primary" onClick={handleShowunlock}>
                      UNLOCK YOUR PROFILE
                    </Button1>

                    <Modal show={showunlock} animation={false}>

                      <Modal.Body className="m-auto text-center py-5">
                        <img src={unlock}></img>
                        <h5>You Unlocked your profile</h5>
                        <p>Get Access to chat, calls and video calls</p>
                        <Button1 variant="primary" onClick={handleCloseunlock} style={{ width: '100%', background: '#D90000', color: 'white', outline: 'none' }}>
                          OK
                        </Button1>
                      </Modal.Body>
                      <Modal.Footer>

                      </Modal.Footer>
                    </Modal>
                  </div>
                }
              </TabContent>
              <TabContent id="block" activeTab={activeTab}>
                {value.items.length > 0 ? block_list : <p className="text-center"><b>No Blocked Profiles</b></p>}
              </TabContent>
              <TabContent id="notification" activeTab={activeTab} >
                <div>
                  <div className="single-notification mt-5" >
                    <p>Liked Profile</p>
                    <label className="switch">
                      <input type="checkbox" id='like-1' checked={status.lstatus} onChange={notificationstatus}></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="single-notification">
                    <p>Views</p>
                    <label className="switch">
                      <input type="checkbox" id='view-3' checked={status.vstatus} onChange={notificationstatus}></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="single-notification">
                    <p>Requests</p>
                    <label className="switch">
                      <input type="checkbox" id='interest-2' checked={status.istatus} onChange={notificationstatus}></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="single-notification">
                    <p>Chat</p>
                    <label className="switch">
                      <input type="checkbox" id='chat-4' checked={status.cstatus} onChange={notificationstatus}></input>
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </TabContent>
              <TabContent id="faq" activeTab={activeTab}>
                {faq.map((item) =>
                  <>
                    <div className="settings_card">
                      <p><b>{item.question}</b><br></br>{item.answer}</p>
                    </div>
                  </>
                )}

              </TabContent>
              <TabContent id="report" activeTab={activeTab}>
                <div className="contacts" id="report_data">
                  <h5 className="text-center" style={{ color: '#D90000' }}>Reports</h5>
                  <hr style={{ color: 'black' }}></hr>


                  <Tabs className="reports_list">
                    <Tab label="Open Reports">

                      <div className="mt-3">
                        {
                          open.length > 0 ? (
                            open.map(reports => (
                              <div key={reports.report_id}>
                                <button className="card-button"
                                  onClick={() => { onReportReply(reports.report_id) }}
                                >
                                  <Card className="notifications_card mb-3">
                                    <CardHeader style={{ width: '20%' }}>
                                      <img src={Admin} className="my-2" />
                                    </CardHeader>
                                    <Card.Body>
                                      <Card.Text>
                                        <p className="m-0 p-0" style={{ fontWeight: 700, color: '#1F2024', fontSize: '15px' }}> {reports.name} </p>
                                        <p className="m-0 p-0" style={{ color: '#71727A', fontSize: '12px' }}>  </p>

                                      </Card.Text>
                                    </Card.Body>

                                  </Card>
                                </button>
                              </div>

                            ))) : <p className="text-center">No Open Reports</p>
                        }

                      </div>

                    </Tab>
                    <Tab label="Closed Reports">
                      <div className="mt-3">
                        {
                          close.length > 0 ? (
                            close.map(reports => (
                              <div key={reports.report_id}>
                                <button className="card-button"
                                  onClick={() => { onReportReply(reports.report_id) }}
                                >
                                  <Card className="notifications_card mb-3">
                                    <CardHeader style={{ width: '20%' }}>
                                      <img src={Admin} className="my-2" />
                                    </CardHeader>
                                    <Card.Body>
                                      <Card.Text>
                                        <p className="m-0 p-0" style={{ fontWeight: 700, color: '#1F2024', fontSize: '15px' }}> {reports.name} </p>
                                        <p className="m-0 p-0" style={{ color: '#71727A', fontSize: '12px' }}>  </p>

                                      </Card.Text>
                                    </Card.Body>

                                  </Card>
                                </button>
                              </div>

                            ))) : <p className="text-center">No Closed Reports</p>
                        }
                      </div>
                    </Tab>
                  </Tabs>



                </div>
                <div style={{ display: 'none' }} id="chat_data">
                  {adminreply && adminreply.length > 0 &&
                    <div>
                      <div className="d-flex align-items-center">
                        <img src={arrow} onClick={() => onBack()} style={{ cursor: 'pointer' }} />
                        <img src={Admin} style={{ width: '10%', marginLeft: '15px' }} />
                        <h4 className="text-center m-0 px-2" style={{ color: '#ff4545' }}>Admin</h4>
                      </div>
                      <hr style={{ color: 'black' }}></hr>
                    </div>
                  }
                  <div style={{ height: '400px', overflow: 'auto' }}>
                    {adminreply ? (
                      adminreply.map(replies => (
                        <div>
                          {replies.send_by === '1' ?
                            <div className='d-flex align-items-center justify-content-end'>
                              <div className='msg sent'>
                                <p> {replies.msg} </p>
                              </div>
                              <div className="avatar"> U </div>
                            </div> :
                            <div className='d-flex align-items-center justify-content-start'>
                              <div className="avatar"> A </div>
                              <div className='msg received'>
                                <p> {replies.msg} </p>
                              </div>
                            </div>}
                        </div>
                      )))
                      : ""}
                  </div>
                  {adminreply && adminreply.length > 0 &&
                    <div className="sendMsg_2">
                      <Input onChange={(e) => { setMsg(e.target.value) }} style={{ width: '100%', fontSize: '15px', fontWeight: '550' }}
                        placeholder='Type message...' type="text" id="msg_input" pattern="^[a-zA-Z][\sa-zA-Z]*" value={msg} />
                      <Button type="submit" className="chat_send" >
                        <img src={send_btn} onClick={msgsend} />
                      </Button>
                    </div>}
                </div>
              </TabContent>
            </div>
            <div style={{ marginTop: '-5rem' }}>
              <TabContent className="links" id="terms" activeTab={activeTab}>
                <iframe src={`${footerUrl}/terms_and_conditions`} style={{ width: '100%', height: '90vh' }} >
                </iframe>
              </TabContent>
              <TabContent id="privacy" activeTab={activeTab}>
                <iframe src={`${footerUrl}/privacy_policy`} style={{ width: '100%', height: '90vh' }} >
                </iframe>
              </TabContent>
              <TabContent id="community" activeTab={activeTab}>
                <iframe src={`${footerUrl}/community_guidelines`} style={{ width: '100%', height: '90vh' }} >
                </iframe>
              </TabContent>
              <TabContent id="cookie" activeTab={activeTab}>
                {Cookie}
              </TabContent>
              <TabContent id="delete_account" activeTab={activeTab}>
              </TabContent>
              <TabContent id="logout" activeTab={activeTab}>
              </TabContent>
            </div>

            <Modal show={showdelete} size="lg" onHide={handleClosedelete} animation={false} aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton className="clse_btn">

              </Modal.Header>
              <Modal.Body className="text-center">Are you sure you want to delete your account? Deleting your account will remove all your profile data, photos, messages, and matches from our system.</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClosedelete}>
                  No
                </Button>
                <Button onClick={(e) => onDeleteAcc(e)} className="up-btn">
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show} size="lg" onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton className="clse_btn">

              </Modal.Header>
              <Modal.Body className="text-center">Are you sure want to logout</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  No
                </Button>
                <Button onClick={(e) => onLogout(e)} className="up-btn">
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>


            <Modal show={showblock} size="lg" onHide={handleCloseblock} animation={false} aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Footer>
                <p className="mt-3 mb-3">Are you sure want to unblock this profile?</p>
                <Button className="up-btn" onClick={handleBlockapi}>
                  Yes
                </Button>
                <Button variant="secondary" onClick={handleCloseblock}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Settings;