import React, { useState, useEffect } from "react";
import {
  CDBSidebar, CDBSidebarContent, CDBSidebarFooter, CDBSidebarHeader, CDBSidebarMenu,
  CDBSidebarMenuItem
} from 'cdbreact';
import { Button, Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './sidemenu.css';
import profiles from "../../assets/images/sideicons/matched_profiles.svg";
import matchrequests from "../../assets/images/sideicons/match_requests.svg";
import listed from "../../assets/images/sideicons/short_listed.svg";
import mutual from "../../assets/images/sideicons/mutual_match.svg";
import requests from "../../assets/images/sideicons/requests.svg";
import chat from "../../assets/images/sideicons/chat.svg";
import likes from "../../assets/images/sideicons/likes.svg";
import viewers from "../../assets/images/sideicons/viewers.svg";
import notification from "../../assets/images/sideicons/notifications.svg";
import settings from "../../assets/images/sideicons/settings.svg";
import profiles_s from "../../assets/images/sideicons/matched_profiles_s.svg";
import matchrequests_s from "../../assets/images/sideicons/match_requests_s.svg";
import listed_s from "../../assets/images/sideicons/short_listed_s.svg";
import mutual_s from "../../assets/images/sideicons/mutual_match_s.svg";
import requests_s from "../../assets/images/sideicons/requests_s.svg";
import chat_s from "../../assets/images/sideicons/chat_s.svg";
import likes_s from "../../assets/images/sideicons/likes_s.svg";
import viewers_s from "../../assets/images/sideicons/viewers_s.svg";
import notification_s from "../../assets/images/sideicons/notifications_s.svg";
import settings_s from "../../assets/images/sideicons/settings_s.svg";
import logout from "../../assets/images/sideicons/logout.svg";
import match from "../../assets/images/sideicons/match.svg";
import match_s from "../../assets/images/sideicons/match_s.svg";
import skip from "../../assets/images/sideicons/skip.svg";
import skip_s from "../../assets/images/sideicons/skip_s.svg";
import payment from "../../assets/images/sideicons/payment.svg";
import payment_s from "../../assets/images/sideicons/payment_s.svg";
import edit from "../../assets/images/sideicons/editpreference.svg";
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import male1 from '../../assets/images/avatarm.png';
import female1 from '../../assets/images/avatarf.png';
import LottieLoader3 from '../lottie/lottieloader3';
import { getDatabase, ref, update } from "firebase/database";



const Sidemenu = () => {
  //const gender = JSON.parse(localStorage.getItem('gender'));

  const [count, setCount] = useState('')
  const [countdata, setcountdata] = useState('')
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  const [show, setShow] = useState(false);
  const [otherlogin, setotherlogin] = useState(false);
  const [profiledata, setprofiledata] = useState('')
  const [selfiepic, setselfiepic] = useState('')
  const handleClose = () => setShow(false);
  const handleOtherClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isNavLinkDisabled = true;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); // Sidebar hidden initially on mobile
  const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

  function makePageTransparent() {
    var overlay = document.getElementById('overlay');
    overlay.style.display = 'block';
  }

  function makePagenotTransparent() {
    var overlay = document.getElementById('overlay');
    overlay.style.display = 'none';
  }

  const [value, setValue] = useState({ 'name': '', 'image': '', 'id': '', 'api_key': '' })
  const local_storage = JSON.parse(localStorage.getItem('userDetails'));
  //console.log(local_storage.user_id)

  const preload = () => {
    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);
    axios({
      method: 'POST',
      url: `${baseUrl}/Profiles/user_profile`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData
    }).then((res) => {
      setprofiledata(res.data.data)
      setselfiepic(res.data.data.selfie_pic)
      setcountdata(res.data.data.unread_count)
      if (res.data.status === 1) {
        localStorage.setItem("locked_status", JSON.stringify(res.data.data.locked_status))
        localStorage.setItem("gender", JSON.stringify(res.data.data.gender))
        localStorage.setItem("selfie_status", JSON.stringify(res.data.data.selfie_status))
        if (res.data.data.selfie_status === '1') {
          setValue({ 'name': res.data.data.name, 'image': `${res.data.path}/${res.data.data.selfie_pic}`, 'id': `#${res.data.data.unique_id}`, 'api_key': res.data.data.api_key })
        } else {
          setValue({ 'name': res.data.data.name, 'image': `${res.data.path}/${res.data.data.selfie_pic}`, 'id': `(#${res.data.data.unique_id})`, 'api_key': res.data.data.api_key })
        }
      }
    }).catch((e) => {
      if (e.message == 'Request failed with status code 401') {
        setotherlogin(true)
        console.log(e.message)
      }
    })
  }

  useEffect(() => {
    preload();
  }, [])

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    // Add the event listener when the component mounts
    window.addEventListener('mousemove', handleMouseMove);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [])

  const db = getDatabase();
  const userRef = ref(db, 'MM4U/' + local_storage.user_id);
  if (mousePosition.x <= 10 || mousePosition.y <= 50) {
    const updates = {
      onlineStatus: false
    };
    updates.onlineStatus = !updates.onlineStatus;
    update(userRef, updates)
      .then(() => {
        //console.log("Online status updated successfully");
      })
      .catch((error) => {
        console.error("Error updating online status: ", error);
      });
  }
  else {
    const updates = {
      onlineStatus: true
    };
    updates.onlineStatus = !!updates.onlineStatus;
    update(userRef, updates)
      .then(() => {
        //console.log("Online status updated successfully");
      })
      .catch((error) => {
        console.error("Error updating online status: ", error);
      });
  }

  const onLogout = (e) => {
    makePageTransparent();
    e.preventDefault();

    const db = getDatabase();
    const userRef = ref(db, 'MM4U/' + local_storage.user_id);
    const updates = {
      onlineStatus: false
    };
    update(userRef, updates)
      .then(() => {
        //console.log("Online status updated successfully");
      })
      .catch((error) => {
        console.error("Error updating online status: ", error);
      });

    const regFormData = new FormData();
    regFormData.append('user_id', local_storage.user_id);

    axios({
      method: 'POST',
      url: `${baseUrl}/Registration/logout`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': local_storage.api_key,
      },
      data: regFormData

    }).then((res) => {
      if (res.data.status === 1) {
        setTimeout(() => {
          navigate('/login', { state: location.state });
          localStorage.removeItem('userDetails');
          localStorage.removeItem('name');
          localStorage.removeItem('userId');
          localStorage.removeItem('gender');
          localStorage.removeItem('new_num');
          localStorage.removeItem('prefer_gender');
          localStorage.removeItem('msg_input');
          localStorage.setItem('gAuth', false);
        }, 2000);
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      } else {
        //alert(res.data.message)
        Toastify({
          text: res.data.message,
          duration: 2000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          onClick: function () { } // Callback after click
        }).showToast();
      }

    }).catch((e) => {
      //  console.log(e.response, 'e')
    })
  };
  // useEffect(() => {

  //   const getUserFormData = new FormData();
  //   getUserFormData.append('user_id',local_storage.user_id)
  //   axios({
  //     method: 'POST',
  //     url: `${baseUrl}/Registration/get_selfie_verification_status`,
  //     headers: {
  //       "Content-type": "multipart/form-data; charset=utf-8; boundary="+Math.random().toString().substr(2),
  //       'X-api-key': local_storage.api_key,
  //     },
  //     data: getUserFormData
  //   }).then((res)=>{
  //     console.log(res.data.status)
  //     if(res.data.data.selfie_status==='1'){
  //       setValue(value.image)
  //     }else{
  //       setValue(gender=='female'? male:female)
  //     }
  //   }).catch((error)=>{
  //     console.log(error)
  //   })
  // },[])

  //console.log(local_storage)

  
  return (

    <>
    {isMobile && (
        <div className="mobile-header">
          <div className="profile-section">
          {profiledata ? (
                <>

                  <img src={`${ local_storage.selfie_status == "1" ? value.image : local_storage.gender == "1" ? male1 : female1  }`} style={{ width: "70px", height: "70px", borderRadius: "50px", background: "#50555c", objectFit: "contain" }} />
                  <div className="d-flex flex-column">
                    <span className="display-name">{value.name}</span>
                    <Link to="/selfieverification" className="unique_id">
                      <span style={{ fontSize: "14px" }}>{value.id}</span>
                    </Link>
                  </div>
                </>
              ) : (
                ""
              )}
          </div>
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            ☰
          </button>
        </div>
      )}
       <div>
      

      {/* Sidebar */}
      {(isSidebarVisible || !isMobile) && (

    <div style={{ display: 'flex', overflow: 'scroll initial', height: '100vh' }}>
      <CDBSidebar textColor="#fff" backgroundColor="#fff">
        <CDBSidebarHeader>
          {/* <i class="fa fa-user"></i> */}
          {profiledata ? <><img src={`${profiledata.selfie_status == '1' ? value.image : local_storage.gender == '1' ? male1 : female1}`} style={{ width: '70px', height: '70px', borderRadius: '50px', background: '#50555c', objectFit: 'contain' }} />
            <div className="d-flex flex-column">
              <span className="display-name">{value.name}</span>
              <Link to="/selfieverification" className="unique_id"><span style={{ fontSize: '14px' }}>{value.id}</span>
              </Link>
            </div></> : ''}
        </CDBSidebarHeader>

        <CDBSidebarMenu className="side_menu">
        <NavLink exact="true" to="/notifications" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={notification} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={notification_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Notifications {countdata != 0 ? <span className="count_data">{countdata}</span> : ''}</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/chat" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={chat} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={chat_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Chat</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/matchedprofiles" activeclassname="active" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={profiles} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /> <img src={profiles_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Matched Profiles</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/mutualmatch" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={mutual} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={mutual_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Mutual Matches</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/Lookingfor" activeclassname="active" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={edit} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect edit" style={{ width: '30px !important' }} />Edit Preferences
            </CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/matches" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={match} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={match_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />My Matches</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/matchrequests" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={matchrequests} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={matchrequests_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Requests Received</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/requests" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={requests} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={requests_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Requested</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/newskipped" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={skip} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={skip_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Skipped Profiles</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/likes" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={likes} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={likes_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Likes</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/shortlist" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={listed} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={listed_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Shortlisted </CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/viewers" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={viewers} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={viewers_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Views</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/settings" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a" ><img src={settings} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={settings_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Settings</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact="true" to="/contactus" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a" ><img src={settings} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" /><img src={settings_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Contact Us</CDBSidebarMenuItem>
          </NavLink>
          {/* <div className="side_li">
            <CDBSidebarMenuItem className="side_li_a">
              <img src={payment} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" />
              <img src={payment_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Payment</CDBSidebarMenuItem>
          </div> */}
          {/* <NavLink exact={true} to="/payment" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={payment} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" />
              <img src={payment_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Payment</CDBSidebarMenuItem>
          </NavLink>    */}
          {/* <NavLink exact={true} to="/payment" activeclassname="activeClicked" className="side_li">
            <CDBSidebarMenuItem className="side_li_a"><img src={payment} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon notSelect" />
              <img src={payment_s} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon selected" />Payment</CDBSidebarMenuItem>
          </NavLink>  */}
          <Button variant="primary" onClick={handleShow} className="logout_btn">

            <CDBSidebarMenuItem className="log_btn"><img src={logout} className="sc-gSAPjG vdkON fa fa-user fa-md side-icon" />Logout</CDBSidebarMenuItem>
          </Button>

          {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
          

          <Modal show={show} size="lg" onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton className="clse_btn">

            </Modal.Header>
            <Modal.Body className="text-center">Are you sure want to logout</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button onClick={(e) => onLogout(e)} className="up-btn">
                Yes
              </Button>
            </Modal.Footer>
            <div id="overlay">
              <div className='AddloadingImage'>
                <LottieLoader3 />
              </div>
            </div>
          </Modal>
          <Modal show={otherlogin} size="lg" onHide={handleOtherClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body className="text-center">Sorry! Someone has logged in with your account.Please check and come back</Modal.Body>
            <Modal.Footer>
              <Button onClick={(e) => navigate('/Login')} className="up-btn">
                ok
              </Button>
            </Modal.Footer>
          </Modal>
        </CDBSidebarMenu>
      </CDBSidebar>
    </div>
      )}
      </div>
      </>

  )
}

export default Sidemenu;