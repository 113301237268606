import React, { useState, useEffect } from "react";
import img_1 from "../assets/images/card_img1.svg";
import img_2 from "../assets/images/card_img2.svg";
import img_3 from "../assets/images/card_img3.svg";
import img_4 from "../assets/images/card_img4.svg";
import img_5 from "../assets/images/phones.png";
import img_6 from "../assets/images/phone2.png";
import img_7 from "../assets/images/phone3.png";
import Header from "./header/header";
import Footer from "./footer/footer";
import axios from "axios";

const Landing = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isSafari, setIsSafari] = useState(false);
    const [showInstallInstructions, setShowInstallInstructions] = useState(false);
    const [isInstalled, setIsInstalled] = useState(
      localStorage.getItem('isInstalled') === 'true' || window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches
    );
  
    useEffect(() => {
      const userAgent = navigator.userAgent;
      const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(userAgent) && !/CriOS|FxiOS/.test(userAgent);
      setIsSafari(isSafariBrowser);
  
      if (!isSafariBrowser && !isInstalled) {
        const handleBeforeInstallPrompt = (event) => {
          event.preventDefault();
          setDeferredPrompt(event);
        };
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
        return () => {
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
      }
    }, [isInstalled]);
  
    const handleInstallClick = () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
            setIsInstalled(true);
            localStorage.setItem('isInstalled', 'true'); // Persist the installation state
          } else {
            console.log('User dismissed the install prompt');
          }
          setDeferredPrompt(null);
        });
      } else if (isSafari) {
        // Show installation instructions for Safari
        setShowInstallInstructions(true);
      }
    };
    return (
        <div>
            <Header />
            {!isInstalled && (
                    <button
                        onClick={handleInstallClick}
                        style={{
                            marginTop: '10px',
                            padding: '10px 20px',
                            background: "#d90000",
                            color: "#ffffff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            display: "block",
                            margin: "0 auto",
                        }}
                        className="d-lg-none d-block"
                    >
                        <span>Install as a Web App</span>
                    </button>
                )}
            <div className="d-flex align-items-center justify-content-center">
                {/* Install button for all browsers, only shown if not installed */}
                

                {/* Safari-specific installation instructions */}
                {showInstallInstructions && isSafari && (
                    <div style={{
                        marginTop: '20px',
                        padding: '15px',
                        backgroundColor: '#333',
                        color: '#ffffff',
                        borderRadius: '8px',
                        textAlign: 'center',
                        maxWidth: '300px'
                    }}>
                        <p>To install this app on your iPhone/iPad:</p>
                        <ol style={{ textAlign: 'left' }}>
                            <li>Tap the <strong>Share</strong> button of the screen.</li>
                            <li>Scroll down and select <strong>Add to Home Screen</strong>.</li>
                            <li>Tap <strong>Add</strong> in the top right corner.</li>
                        </ol>
                        <button
                            onClick={() => setShowInstallInstructions(false)}
                            style={{
                                marginTop: '10px',
                                padding: '5px 10px',
                                backgroundColor: '#b8003e',
                                color: '#ffffff',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}
                        >
                            Close
                        </button>
                    </div>
                )}

            </div>


            <div className="text-center mt-4">
                <p className="text mb-2">Online dating has become a fun and quick way to meet likeminded individuals. You could be one click away from finding your soulmate.</p><p> Let <span className="blink-soft" style={{ color: '#D90000', fontWeight: '600' }}>Rajakirani</span> make it happen!</p>
            </div>
            <div className="d-flex main_card mb-4">
                <div className="card card_body">
                    <img src={img_1} />
                    <div className="card-body text-center">
                        <h5 className="card-title">Simple to use </h5>
                        <p className="card-text">Easy steps to get connected</p>
                    </div>
                </div>

                <div className="card card_body">
                    <img src={img_2} />
                    <div className="card-body text-center">
                        <h5 className="card-title">Smart Matching</h5>
                        <p className="card-text"> Create connections with users that are like you.</p>
                    </div>
                </div>

                <div className="card card_body">
                    <img src={img_3} />
                    <div className="card-body text-center">
                        <h5 className="card-title">Filter & Refine</h5>
                        <p className="card-text">Refine your search and save browsing time</p>
                    </div>
                </div>

                <div className="card card_body">
                    <img src={img_4} />
                    <div className="card-body text-center">
                        <h5 className="card-title">Stay Connected</h5>
                        <p className="card-text">Be part of our community and make it happen</p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>

    )
}

export default Landing;