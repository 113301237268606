import React, {useEffect, useState} from 'react';
import { Card } from 'react-bootstrap';
import success from '../../assets/images/success.gif'
import Sidemenu from "../sidebar/sidemenu";
import axios from 'axios';
import img from '../../assets/images/logo.svg'


function Paymentsuccess() {
  const paydetails = JSON.parse(sessionStorage.getItem('order'));
  const local_storage=JSON.parse(localStorage.getItem('userDetails'));
  const baseUrl=process.env.REACT_APP_BASE_URL;
  const [pay, setPay] = useState([]);


  useEffect(() => {
    if (local_storage) {
      // Update the subscription status to "1"
      local_storage.subscription_status = "1";

      // Save the updated object back to local storage
      localStorage.setItem('userDetails', JSON.stringify(local_storage));
  }
    const payData=new FormData();
    payData.append('user_id', local_storage.user_id);
    axios({
        method: 'POST',
        url: `${baseUrl}/Payments/payment_success`,
        headers: {
            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
            'X-api-key': local_storage.api_key,
        },
        data: payData
    }).then((res) => {
        console.log(res)
        setPay(res.data.data)
        localStorage.setItem('subscription_status', '0');
    }).catch((e) => {
        console.log(e)
    })
}, [])

  return (
    <div>

      <div className="d-lg-flex d-block">
        <div className="col-3 page_left" style={{ width: 'auto' }}>
          <Sidemenu />
        </div>
        <div className="col-9 d-flex flex-column payment-page page_right">
        <div className="d-flex flex-row bg-white top-bar shadow">
        <img src={img} className="w-50 site_logo d-block m-auto inner-logo"/>
        </div>
          <Card className='mt-5 col-10 col-lg-6 pay_success' >
            <img src={success} className='w-50'/>
            <h4><b>Payment Success</b></h4>
            <p><b>Transaction Id</b>: {pay.transaction_id}</p>
            <p><b>Amount Paid</b>: {pay.recharge_price}</p>
            <hr></hr>
            <p style={{color: '#D90000'}}><b>Your membership is successfully upgraded.  Start using the premium account
            </b></p>
          </Card>
        </div>
      </div>

    </div>
  )
}

export default Paymentsuccess;