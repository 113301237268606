import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Footer from "../footer/footer";
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from "../../assets/images/logo.svg";
import { useNavigate } from 'react-router-dom';

function Membership() {
  
  const navigate = useNavigate();
  const [payment, setPayment] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const Auth = process.env.REACT_APP_USER_TOKEN;
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${baseUrl}/Profiles/subscriptions`,
      headers: {
        "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'X-api-key': Auth,
      }
    }).then((res) => {
     // console.log(res.data.data)
      setPayment(res.data.data)
    }).catch((e) => {
      console.log(e)
    })
  }, [])

  return (
    <div>
      <Navbar expand="lg" className="home_data">
        <Container fluid>
          <Navbar.Brand href="/" className="col-6">
            <img
              src={logo} className='site_logo'
              style={{ display: 'block', margin: 'auto' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-lg-0 menu col-6"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link onClick={() => navigate('/')} className="px-5 text-white">Home</Nav.Link>
              <Nav.Link onClick={() => navigate('/aboutpage')} className="px-5 text-white">About</Nav.Link>
              <Nav.Link onClick={() => navigate('/membership')} href="#" className="px-5 text-white active">Membership</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className='p-5 member_bg'>
        <div className='h-50 w-100 membership_bg'>
        <h1 className='text-center main_title blink-soft' style={{color:'white', fontSize:'4.5rem'}}> Join for Free </h1>
        </div>

      {/* <div className="d-flex p-5">
        {payment.map((item) => <Card.Body style={{textAlign:'center'}}>
          <Button class="text-card shadow" className="plan_btn p-5" style={{boxShadow:'0px 0px 10px 0px red'}}>
            <p>{item.till_months} months</p>
            <p>{item.plan_name}</p>
            <p>${item.plan_price}</p>
          </Button>
        </Card.Body>)}</div> */}
        
        </div>
      <Footer />
    </div>

  )
}

export default Membership;